<template>
  <header class="login-header">
    <div
      v-if="props.showBackButton"
      class="login-back"
      :class="{ 'login-back--custom': customLogo }"
      @click="emit('backToLogin')">
      <DrIcon icon="fa-chevron-left" />
    </div>
    <img v-if="props.customLogo" class="login-logo" :src="props.customLogo" />
    <div v-else class="login-title">
      <h1><slot /></h1>
    </div>
  </header>
</template>

<script setup lang="ts">
import DrIcon from "@/src/ui/dr-icon.vue"

type Props = {
  showBackButton: boolean
  customLogo?: string
}

const props = defineProps<Props>()

type Emits = {
  (event: "backToLogin"): void
}

const emit = defineEmits<Emits>()
</script>

<style lang="scss" scoped>
.login {
  &-header {
    position: relative;
    padding: 40px 0;
  }

  &-back {
    position: absolute;
    top: calc(50% - 12px);
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
    background-color: $dr-charcoal-grey-lightest;
    border-radius: 50%;
    font-size: $dr-font-size-caption;
    cursor: pointer;

    &:hover {
      background-color: $dr-charcoal-grey-lighter;
    }

    &--custom {
      left: -28px !important;
      top: 10% !important;
    }
  }

  &-title {
    h1 {
      margin: 0;
      text-align: center;
      font-family: $dr-font-family-secondary;
      font-size: $dr-font-size-title;
      font-weight: bold;
    }
  }
}
</style>
