/**
 * Transforms the location hash with panel info to valid url
 * matching the pattern `/front/<project>/panel/<path:panel_path>`.
 */
export function transformLocationHash(location: Window["location"]): string {
  if (!location.hash || !location.search) {
    return ""
  }

  // we are in login before deep link
  // we move next=front/project#/panel/panelname to next=front/project/panel/panelname
  let panelHash = `${location.hash.slice(1)}`

  if (location.search.endsWith("%2F") && panelHash.startsWith("/")) {
    panelHash = panelHash.slice(1)
  } else if (!location.search.endsWith("%2F") && !panelHash.startsWith("/")) {
    panelHash = `/${panelHash}`
  }

  return location.search + encodeURIComponent(panelHash)
}

export function encodeObject(object: Object): string {
  return encodeURIComponent(JSON.stringify(object))
}

export function decodeObject(encodedObject: string): Object {
  return JSON.parse(decodeURIComponent(encodedObject))
}
