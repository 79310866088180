<template>
  <div class="login-banner">
    <img src="/static/img/deepki/new_deepki_logo_dark.svg" alt="Deepki Logo" />
    <a href="/gdpr" target="_blank">{{
      translate({ defaultMessage: "gdpr", description: { tags: "login" } })
    }}</a>
  </div>
</template>

<script setup lang="ts">
import { useTranslate } from "@/src/composables/use-translate"

const { translate } = useTranslate()
</script>

<style lang="scss" scoped>
.login-banner {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 $dr-margin-bigger;
  background-color: rgba(255, 255, 255, 0.88);

  a {
    color: $dr-color-primary;
    font-size: $dr-font-size-caption;
    cursor: pointer;
  }

  img {
    height: 29px;
  }
}
</style>
